const zipValidationRegexMap: Record<string, RegExp> = {
  de: /^\d{5}$/,
  nl: /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i,
  at: /^\d{4}$/,
};

export function useZipCodeValidation(
  langCode?: string,
): [rule: string, ...args: any[]][] {
  const languageCode = langCode ?? (useNuxtApp().$languageCode as string);
  const zipValidationRegex =
    zipValidationRegexMap[languageCode] ?? zipValidationRegexMap['de'];
  return [['matches', zipValidationRegex]];
}
